.loading-spinner{
     justify-content: center;
     display: flex;
     transform: translateY(50%);
     animation: fade-in ease 3;
     animation-iteration-count: 1;
     animation-fill-mode: forwards;
}

@keyframes fade-in{
     0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
}

.contact-page{
     margin-left: 10%;
     margin-right: 10%;
     margin-top: 5%;
}

.contact-title{
     font-size: min(80px, 10vw);
     color: white;
     line-height: min(80px, 10vw);
     word-spacing: 0;
     text-align: center;
     width: auto;
     font-family: "League Spartan";
     font-weight: 800;
     margin-bottom: 0;
     margin-top: 0;
}

.contact-label{
     font-size: min(30px, 30vw);
     color: white;
     line-height: min(80px, 10vw);
     font-weight: normal;
     font-family: "Urbanist";
     text-align: left;
     width: auto;
     letter-spacing: 0;
}

.contact-container {
     padding: 20px;
}

.contact-input{
     width: 100%;
     height: min(77px, 10vw);
     border-radius: 15px;
     background-color: #FFE8E6;
     font-size: min(30px, 5vw);
     margin-bottom: 15px;
     padding-left: 12px;
     padding-right: 12px;
}

.contact-input-message{
     width: 100%;
     height: 179px;
     border-radius: 15px;
     background-color: #FFE8E6;
     font-size: min(30px, 5vw);
     margin-bottom: 20px;
     padding-left: 12px;
     line-height: min(40px, 8vw);
     resize: none;
}

.contact-submit{
     background: #F2958D;
     text-align: center;
     font-family: "Urbanist";
     font-weight: normal;
     font-size: min(30px, 5vw);
     line-height: 16px;
     font-weight: 400;
     color:white;
     border-style: solid;
     border-radius: 100px;
     border-color: rgba(60, 106, 166, 0.5);
     border-width: 1px;
     padding: 10px 21px 10px 21px;
     height: min(60px, 10vw);
     cursor: pointer;
}



.about-outer {
     height: 100vh;
     width: 100%;
     background-color: #000D27;
}

.about-container {
     width: 80%;
     justify-items: center;
     /* align-items: center; */
     text-align: center;
     /* vertical-align: middle; */
     display: grid;
     grid-template-columns: repeat(2, minmax(400px, 1fr));
     grid-template-rows: masonry;
     gap: 5vw;
     /* align-items: center; */
     margin: auto;
     border-collapse: collapse;
     table-layout: fixed;
     flex-wrap: wrap;
     background-color: #000D27;
}


.about-title {
     font-family: "League Spartan";
     font-size: min(80px, 12vw);
     font-weight: 700;
     line-height: normal;
     color: white;
     /* margin-left: 3%; */
     padding-top: 4%;
     padding-bottom: 4%;
     background-color: #3C6AA6;
     text-align: center;
}

.about-slogan {
     color: #ffffff;
     font-family: "Urbanist";
     font-size: min(4vw, 40px);
     line-height: min(6vw, 60px);
     align-self: center;
     font-optical-sizing: auto;
}

.about-entry-container{
     /* background-color: #f2a766; */
     /* width: min-content; */
     /* width: 100%; */
     /* display: flex; */
     padding: 10px;
     margin-bottom: 10px;
     border-radius: 8px;
     /* align-self:center; */
     vertical-align: top;
     block-size: min-content;
}

.about-entry-description{
     color: white;
     font-size: 20px;
     font-style: normal;
     font-family: 'Urbanist';
     font-weight: 400;
     /* line-height: 80%; */
     text-align: center;
     padding-left: 4px;
     white-space: pre-wrap;
}

.about-entry-title{
     color: white;
     font-family: "League Spartan";
     font-size: min(30px, 8vw);
     line-height: min(30px, 8vw);
     font-weight: 800;
     /* background-color: #f2a766 */
}

.about-entry-name{
     color: white;
     font-family: "League Spartan";
     font-size: min(40px, 8vw);
     line-height: min(40px, 8vw);
     font-weight: 800;
}

.about-entry-picture{
     border-radius: 8px;
     margin-bottom: 2%;
     /* padding-bottom: 2%; */
     width: 270px;
     height: 270px;
     border-style: solid;
     border-color: #f2a766;
     border-width: 8px;
     object-fit: cover;
}

@media only screen and (max-width: 850px){
     .about-container{
          grid-template-columns: 1fr;
     }
}

@media only screen and (min-width: 1250px) {
     .about-container{
          width: 60%;
     }
}
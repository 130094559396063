.blog-entry{
     padding-left: 10%;
     padding-right: 10%;
     padding-top: 5%;
     padding-bottom: 5%;
     border-bottom: 1px solid transparent;
     background-size: 100%;
}

body{
     /* background-color: #000D27; */
}

.blog-header{
     color: white;
     font-family: "League Spartan";
     font-size: min(40px, 8vw);
     line-height: min(40px, 8vw);
     font-weight: 800;
     text-align: left;
     display: flex;
}

.blog-paragraph{
     color:white;
     font-size: min(20px, 7vw);
     line-height: min(24px, 7vw);
     letter-spacing: 0%;
     font-family: "Urbanist";
     font-weight: 400;
     font-style: normal;
     display: flex;
     background: none;
     resize: none;
     border: none;
     white-space: pre-line;
}

.blog-list{
     color:white;
     font-family: "Urbanist";
}

.blog-entry-button{
     cursor: pointer;
     background-color: transparent;
     outline: none;
     background-repeat: no-repeat;
     overflow: hidden;
     border: none;
}

.blog-image{
    max-width: 100%;
    border-radius: 4px;
}

/* .work-outer, .work-outer-visible{
     scroll-snap-type: y mandatory;
     scroll-snap-align: center;
} */

html{
     scroll-snap-type: y mandatory;
     overflow-y: scroll;
     max-height: 100vh;
     /* scroll-snap-align: start; */
     scroll-behavior: smooth;
}

.work-outer {
     background-color: #000D27;
}

.work-outer-visible{
     background-color: #EFF1F3;
}


.work-container {
     /* background-image: none; */
     /* background-attachment: fixed; */
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     height: max(45vh, 350px);
     width: 100%;
}

.work-container2 {
     /* background-image: none; */
     /* background-attachment: fixed; */
     /* background-color: #EFF1F3; */
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     height: min(20vw, 350px);
     width: 100%;
}

.work-logo-container {
     width: 100%;
     height: min-content;
     background-image: url("~/public/thevoid.GIF");
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     align-items: center;
     background-color: #EFF1F3;
     display: grid;
}

.work-logo{
     width: 90%;
     margin: auto;
     align-self: center;
}

.work-title {
     color: #ffffff;
     font-family: "League Spartan";
     font-size: min(8vw, 80px, 8vh);
     font-weight: 800;
     line-height: min(8vw, 80px, 8vh);
     font-optical-sizing: auto;
     font-style: normal;
     text-transform: uppercase;
     text-align: center;
     margin: auto;
     width: 80%;
     padding-top: 10%;
     padding-bottom: 10%;
     /* position: fixed; */
     /* left: 50%;
     transform: translateX(-50%); */
}

@keyframes scroll-watcher {
     to { scale: 1 1; }
}

.work-slogan {
     color: #000D27;
     font-family: "Urbanist";
     font-size: min(7vw, 60px);
     line-height: min(8vw, 80px);
     font-weight: 800;
     text-transform: uppercase;
     text-align: center;
     margin: auto;
     align-self: center;
     font-optical-sizing: auto;
}

.work-copy{
     color: #000D27;
     font-family: "Urbanist";
     font-size: 24px;
     font-style: normal;
     font-weight: 400;
     line-height: 30px;
     text-align: left;
}

.announcement-container {
     width: 80%;
     text-align: center;
     margin: auto;
     align-self: center;
     margin-bottom: 8%;
     background-color: #EFF1F3;
}

.wishlist-button, .wishlist-button:hover, .wishlist-button:focus, .wishlist-button:active {
     width: 85%;
     height: 60px;
     justify-content: center;
     align-items: center;
     border-radius: 100px;
     border: 1px solid rgba(60, 106, 166, 0.50);
     background: #F2958D;
     color: #fff;
     text-align: center;
     font-size: 30px;
     font-style: normal;
     font-weight: 400;
     line-height: 25px;
     text-decoration: none !important;
     cursor: pointer;
     margin-bottom: 8px;
}